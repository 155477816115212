



























































































import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Component, Vue } from 'vue-property-decorator';
import CBudgHeadReq from '@/modules/budget-request/budget-header.vue';
import CBudgetSumCalcTab from '@/modules/budget-request/components/budget-sum-calc/budget-sum-calc-table.vue';
import Multiselect from 'vue-multiselect';
import { Ax } from '@/utils';
import CBudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import { vue } from '@/services/i18n';

@Component({
    components: {
        'c-budg-head': CBudgHeadReq,
        'multiselect': Multiselect,
        'c-bud-sum-calc-tab': CBudgetSumCalcTab,
        'c-budg-form-lst': CBudgetFormsList
    }
})
export default class CBudgetSumCalc extends Vue {
    private curFormSelect = '/budget-sum-calc';
    private headerData: any | null = null;
    private header: any | null = null;
    private showAdd = false;
    private error: any;
    private dataTypeFilter: any = null;

    private filter: any = [];

    private save() {
        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.budSumCalcTab.save();
    }

    private infoCostForm(info_id: any) {
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/get-info/' + info_id, //  Тело файла
                method: 'POST',
                data: null,
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                let file = '';
                if (info_id == 10) {
                    file = 'РП_Бюджетные_заявки_сводные_расчеты';
                }
                link.setAttribute('download', file + '.pdf');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                that.error = error;
            }
        );
    }

    private chgData(data: any) {
        this.filter = data;
        const curHeader = data;
        const budgetVers = curHeader.budgetVersion ? curHeader.budgetVersion.variant_uuid : null;
        console.log(data);
        if ((curHeader !== null) && (curHeader.dataType !== null) && (curHeader.gr !== null) && (curHeader.gu !== null) && (curHeader.prg !== null)) {
            this.header = {
                year: curHeader.year,
                // eslint-disable-next-line @typescript-eslint/camelcase
                data_type: curHeader.dataType.code,
                gu: curHeader.gu.code,
                budgetVersion: budgetVers,
                // eslint-disable-next-line @typescript-eslint/camelcase
                id_region: curHeader.region,
                gr: curHeader.gr.gr,
                abp: curHeader.abp.abp,
                prg: curHeader.prg.prg,
                ppr: (curHeader.pgr === null ? null : curHeader.pgr.ppr),
                locale: this.$i18n.locale
            };
            this.dataTypeFilter = curHeader.dataType.name;
        }
    }

    private openFilterByRef(refName: string) {
        const foo: any = this.$refs.budgetHeader;
        foo.openFilterByRef(refName);
    }

    private downloadRep() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/budg_sum_calc57/57/' + JSON.stringify(that.header),
                method: 'POST',
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Приложение 57 Сводный расчет расходов ГУ по БП.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                that.error = error;
            }
        );
    }

    private downloadRepSV() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/budg_sum_calc57/58/' + JSON.stringify(that.header),
                method: 'POST',
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Приложение 58 Сводный расчет расходов АБП по БП.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                that.error = error;
            }
        );
    }
    private downloadRepBP() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/budg_sum_calc59/59/' + JSON.stringify(that.header),
                method: 'POST',
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Приложение 59 Сводный перечень БП.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                that.error = error;
            }
        );
    }
    private downloadRepTBP() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/budg_sum_calc60/60/' + JSON.stringify(that.header),
                method: 'POST',
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Приложение 60 Сводная таблица расходов по БП.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                that.error = error;
            }
        );
    }
    private downloadRepBPR() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/budg_sum_calc61/61/' + JSON.stringify(that.header),
                method: 'POST',
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Приложение 61 Сводная таблица расходов по БПП.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                that.error = error;
            }
        );
    }

    private isDownloadButtonEnabled = false;

    private downloadBatchRep() {
        this.isDownloadButtonEnabled = true;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/budg_sum_calc_batch_reports/' + JSON.stringify(that.header),
                method: 'POST',
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Пакетная выгрузка режим форма ГУ.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
                this.isDownloadButtonEnabled = false;
            },
            (error) => {
                that.error = error;
                this.isDownloadButtonEnabled = false;
            }
        );
    }
}
