export function setNameLang (locale: string, obj: any, codeName?: any | null): any {
    let txt = obj['name_' + locale];
    if (!txt) { txt = obj.name_ru; }
    if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
        txt = obj[codeName] + ' - ' + txt;
    }
    const el = Object.assign({}, obj);
    el.name = txt;
    return el;
}

export function makeToast(vue: any, variant: any, title: string, tostbody: any) {
    vue.$bvToast.toast(tostbody, {
        title: title,
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
        appendToast: true
    });
}